import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { map } from 'rxjs';
import { IFooterBranding } from 'src/app/api/modules/core/components/static/IFooterBranding';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { ThemeService } from '../../../services/theme/theme.service';
import { FooterBrandingModel } from './footer-branding.model';

/**
 * The Default State
 */
function initialState(): FooterBrandingModel {
  return {
    lightThemeImage: undefined,
    darkThemeImage: undefined,
    image: undefined,
    text: undefined,
    link: undefined,
  };
}

/**
 * The Store used for a {@link FooterBrandingComponent}.
 */
@Injectable()
export class FooterBrandingRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `footer-branding-${uniqueId()}`,
    },
    withProps<IFooterBranding>(initialState()),
  );

  constructor(
    private readonly themeService: ThemeService,
    private readonly loggerService: LoggerService,
  ) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: IFooterBranding) {
    let lightImage = configuration?.lightThemeImage;
    let darkImage = configuration?.darkThemeImage;

    if (configuration?.image) {
      this.loggerService.warn(
        '[FooterBranding] The "image" property is deprecated. Provide the lightImage and darkImage properties instead to be compatible with the theme.',
      );
      lightImage = configuration.image;
      darkImage = configuration.image;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.lightThemeImage = lightImage;
        state.darkThemeImage = darkImage;
        state.text = configuration?.text;
        state.link = configuration?.link;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Contains the light branding image.
   *
   * This is displayed on light mode depending on the theme.
   */
  private _lightThemeImage = this.store.pipe(select((state) => state.lightThemeImage));

  /**
   * Contains the dark branding image.
   *
   * This is displayed on dark mode depending on the theme.
   */
  private _darkThemeImage = this.store.pipe(select((state) => state.darkThemeImage));

  /**
   * Contains the branding image
   *
   * Optionally shows the branding of who made this configuration
   *
   * The default is undefined
   */
  private _image$ = ElfCombineQueries([this.themeService.isDarkMode$, this._lightThemeImage, this._darkThemeImage]).pipe(
    map(([darkMode, lightThemeImage, darkThemeImage]) => {
      // the final image to use
      let image = lightThemeImage || darkThemeImage || undefined;

      // if in dark mode, use the dark image otherwise use the light image
      if (darkMode) {
        if (darkThemeImage) {
          image = darkThemeImage;
        }
      } else {
        if (lightThemeImage) {
          image = lightThemeImage;
        }
      }

      return image;
    }),
  );

  /**
   * Contains the branding text
   *
   * The default is undefined
   */
  private _text$ = this.store.pipe(select((state) => state.text));

  /**
   * Contains the branding link
   *
   * The default is undefined
   */
  private _link$ = this.store.pipe(select((state) => state.link));

  /**
   * The Template Data
   */
  templateData$ = ElfCombineQueries([this._image$, this._text$, this._link$]).pipe(map(([image, text, link]) => ({ image, text, link })));
}
