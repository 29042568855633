/* eslint-disable @typescript-eslint/no-unused-vars */

import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { DigitaServiceError } from '@digitaservice/utils';
import { AppService } from './modules/core/services/application/application.service';
import { DialogService } from './modules/core/services/dialog.service';
import { SnackbarService } from './modules/core/services/snackbar.service';

/**
 * Handles all final global errors in the system
 *
 * See https://github.com/scttcper/ngx-toastr/issues/179#issuecomment-325724269
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  /**
   * Constructor
   * Because the ErrorHandler is created before the providers, we need the injector
   */
  constructor(private readonly injector: Injector) {
    super();
  }

  /**
   * Handle all Errors that slip through the cracks.
   * @param error - Either a Runtime Error or a Network Error
   */
  override handleError(error: Error | DigitaServiceError | HttpErrorResponse) {
    switch (error.name) {
      case 'DigitaServiceError':
        this.handleDigitaServiceError(error as DigitaServiceError);
        break;
      case 'HttpErrorResponse':
        this.handleHTTPError(error as HttpErrorResponse);
        break;
      default:
        this.handleStandardError(error as Error);
        break;
    }

    // instana error report
    try {
      if (ineum) {
        ineum('reportError', error);
        ineum('terminateSession');
      }
    } catch (e) {
      // will fail if instana is not loaded
    }

    // dismiss any open dialogs or snackbars
    const dialogService = this.injector.get<DialogService>(DialogService);
    dialogService?.dismiss();

    const snackbarService = this.injector.get<SnackbarService>(SnackbarService);
    snackbarService?.dismiss();

    throw error;
  }

  /**
   * Handles a generic Error
   *
   * @param error - the generic error
   */
  private handleStandardError(error: Error) {
    const to = new DigitaServiceError(error.message, undefined, error.stack);
    this.dispatchToStore(to);
  }

  /**
   * Handles a HTTP Response.
   *
   * @param error - the HTTP Error Response
   */
  private handleHTTPError(error: HttpErrorResponse) {
    const to = new DigitaServiceError(`${error.name} : ${error.status} - ${error.statusText}`);
    this.dispatchToStore(to);
  }

  /**
   * Handles a DigitaService Error Data.
   *
   * @param error - the DigitaService Error
   */
  private handleDigitaServiceError(error: DigitaServiceError) {
    this.dispatchToStore(error);
  }

  /**
   * Dispatches data to the store
   *
   * @param errorAction - the error action to dispatch
   */
  private dispatchToStore(error: DigitaServiceError) {
    const store = this.injector.get<AppService>(AppService);
    store.registerError(error);
  }
}
